import React from 'react';
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import CTA from '../components/CTA'
import HelpCTA from '../components/HelpCTA'
import neon from '../images/open-neon-signage-turned-on-2995188@2x.png'
import notes from '../images/people-notes-meeting-team-1@2x.png'
import help from '../images/HereToHelp_3000x3000.png'
import podcast from '../images/close-up-fashion-female-girl-373945.png'
import covid from '../images/Coronavirus_3000x3000.png'
export default () => (
    <div id="app">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="We have a long history of being a community resource and providing coverage you can count on. These times are no different. View our helpful resources"></meta>
          <title>Here to Help | Coronavirus Resources | WRAL Digital Solution</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/here-to-help/" />
        </Helmet>
      <PreHeader/>
      <Header/>
      <h1 className="business--title">Here to Help</h1>
      <div className="business">

      <div className= "business_heading">
              <h2 className="business_block--header">As part of Capitol Broadcasting Company, we have a long history of being a community resource and providing coverage you can count on. These times are no different. Below are some business resources we hope you'll find helpful:</h2>
        </div>
      <div className = "business_wrapper">
          <div className = "business_grid">
          <div className= "business_block">
              <img src={covid} className="business--image-resources" alt="Here to Help | Coronavirus Resources | WRAL Digital Solution"/>
              <h3 className="business_block--title">Subscribe to the WRAL Coronavirus newsletter</h3>
              <p className="business_block--text">Once a day, receive an email detailing all of the recent news and updates on Coronavirus and its impact on life and business in our great state.</p>
                <div className="business_cta">
                    <a href="https://www.wral.com/coronavirus/get-daily-email-updates-on-the-coronavirus/18997034/" target="_blank"><button type="button" className="cta_contact-us--desktop">Sign Up</button></a>
                    <a href="https://www.wral.com/coronavirus/get-daily-email-updates-on-the-coronavirus/18997034/" target="_blank"><button type="button" className="cta_contact-us--mobile">Sign Up</button></a>
                </div>
            </div>
            <div className= "business_block">
              <img src={podcast} className="business--image-resources" alt="Here to Help | Coronavirus Resources | WRAL Digital Solution"/>
              <h3 className="business_block--title">Subscribe to the WRAL Coronavirus podcast</h3>
              <p className="business_block--text">Get all the latest news and information on the virus and how it is impacting North Carolina.</p>
                <div className="business_cta">
                    <a href="https://www.wral.com/coronavirus/north-carolina-coronavirus-covid-19-podcast/19015999/" target="_blank"><button type="button" className="cta_contact-us--desktop">Subscribe</button></a>
                    <a href="https://www.wral.com/coronavirus/north-carolina-coronavirus-covid-19-podcast/19015999/" target="_blank"><button type="button" className="cta_contact-us--mobile">Subscribe</button></a>
                </div>
            </div>
            <div className= "business_block">
              <img src={help} className="business--image-resources" alt="Here to Help | Coronavirus Resources | WRAL Digital Solution"/>
              <h3 className="business_block--title">"Here to Help" Virtual Conference Series</h3>
              <p className="business_block--text">Tune in for free, weekly advice from industry experts and local business owners on what you can do to keep your business alive during a crisis.</p>
                <div className="business_cta">
                    <a href="https://register.gotowebinar.com/register/372079539019581965?source=WRAL+Ads" target="_blank"><button type="button" className="cta_contact-us--desktop">Register</button></a>
                    <a href="https://register.gotowebinar.com/register/372079539019581965?source=WRAL+Ads" target="_blank"><button type="button" className="cta_contact-us--mobile">Register</button></a>
                </div>
            </div>
          </div>
        </div>

        <div className = "business_wrapper">
          <div className = "business_grid-two">
          <div className= "business_block">
              <img src={neon} className="business--image-resources" alt="Here to Help | Coronavirus Resources | WRAL Digital Solution"/>
              <h3 className="business_block--title">Tell us how you're pivoting your business</h3>
              <p className="business_block--text">We know that businesses are struggling and also adapting and innovating in new and unexpected ways. We'd love to hear how your local business is responding to the impacts of the cornoavirus.</p>
                <div className="business_cta">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdgKdnRUzNJTz82FcBOU3_YuUr9CeD9I2QCS-2iJzYMcEPXpw/viewform" target="_blank"><button type="button" className="cta_contact-us--desktop">Let us know</button></a>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdgKdnRUzNJTz82FcBOU3_YuUr9CeD9I2QCS-2iJzYMcEPXpw/viewform" target="_blank"><button type="button" className="cta_contact-us--mobile">Let us know</button></a>
                </div>
            </div>
            <div className= "business_block">
              <div className="business_block--list-text">MORE HELPFUL LINKS</div>
              <div className="business_block--list-text"><a href="https://www.wral.com/Support-local-raleigh-durham-businesses/19014546/" target="_blank">Support local businesses</a></div>
              <div className="business_block--list-text"><a href="https://www.wral.com/Triangle-restaurants-offering-catering-delivery-and-take-out/19016220/" target="_blank">Local restaurants you can support</a></div>
              <div className="business_block--list-text"><a href="https://www.wral.com/lost-your-job-due-to-coronavirus-slowdown-here-s-how-to-get-help/19010660/" target="_blank">Ways you can help your neighbors</a></div>
              <div className="business_block--list-text"><a href="https://www.wral.com/list-of-resources-to-help-seniors-get-groceries-and-prescriptions/19015969/" target="_blank">Resources to help seniors get groceries and prescriptions</a></div>
              <div className="business_block--list-text"><a href="https://www.wral.com/coronavirus/donate-united-way-wral-drive-helps-our-community-through-coronavirus-crisis/19010660/" target="_blank">Ways to support individuals and non-profits</a></div>
            </div>
          </div>
        </div>


        <div className="business_banner">
            <h3 className="business_banner-text">
              To get started today <a href="tel:+1-919-744-3030">call</a> or fill out our contact form.
            </h3>
          <div className="advertising_cta">
              <HelpCTA id= "HereToHelpCTA" text="Contact Us" type="button" link="/contact" classname="contact-us--mobile"/>
              <HelpCTA id= "HereToHelpCTA" text="Contact Us" type="button" link="/contact" classname="contact-us--desktop"/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );